import { Button, FlatCard, Section } from "@darktrace/ui-components";
import React from "react";
import { PRODUCT_INFO_MAP } from "../../logic/util.js";
import { useProductLoginMutation, useUserAccessFlags } from "../../logic/api.js";
import { useNavigate } from "react-router-dom";
import { useActiveClientId } from "../../logic/hooks.js";

export function NoProductView({ availableInstancesForUser, servicesForSelectedDeployment }) {
  const activeClientId = useActiveClientId();
  const { data: userAccessFlags = [] } = useUserAccessFlags({ clientId: activeClientId });

  const canUserAccessUserManagement = userAccessFlags.includes("user-management");
  const canUserAccessCustomerPortal = availableInstancesForUser.some((instance) => instance.serviceType.includes("customer-portal"));
  const customerPortalServiceType = "customer-portal-service-type";
  const customerPortalService = servicesForSelectedDeployment.find((service) => service.serviceType === customerPortalServiceType);
  const customerPortalServiceName = PRODUCT_INFO_MAP[customerPortalServiceType]?.name ?? customerPortalService.serviceLabel;
  const navigate = useNavigate();

  const { mutate: loginMutation } = useProductLoginMutation({ clientId: activeClientId });
  return (
    <>
      <FlatCard>
        <div className="no-access-card-contents">
          <span className="no-access-card-header">
            {t(`The ActiveAI Security Portal is your central landing point for Darktrace products, services, and support.`)}
          </span>

          <div className="no-access-buttons">
            <Section title={t(`DARKTRACE PRODUCTS`)}>
              <div className="section-content">
                <div>
                  <span>{t(`You don't have access to any Darktrace products yet. `)}</span>
                  <span>
                    {canUserAccessUserManagement
                      ? t(`You can assign yourself (and other users) access to products in the User Management page.`)
                      : t(`Contact your administrator to get access.`)}
                  </span>
                </div>
              </div>
            </Section>
            <Section title="CUSTOMER PORTAL">
              <div className="section-content">
                <div>
                  <span>{t(`You can get in contact with Darktrace and find Product Guides in Customer Portal.`)}</span>
                  {canUserAccessCustomerPortal && <span>{t(` Contact your administrator to get access.`)}</span>}
                </div>

                {canUserAccessCustomerPortal && (
                  <Button
                    style={{ marginTop: "1rem" }}
                    variant="secondary"
                    onClick={() => {
                      loginMutation({
                        serviceId: customerPortalService.serviceId,
                        serviceLabel: customerPortalServiceName,
                        instanceId: customerPortalService.instances[0].instanceId,
                      });
                    }}
                  >
                    {customerPortalServiceName}
                  </Button>
                )}
              </div>
            </Section>

            {canUserAccessUserManagement && (
              <Section title={t(`ADD & ASSIGN USERS`)}>
                <div className="section-content">
                  <div>
                    { /* prettier-ignore */}
                    <span>
                      {t(`You can invite other users from your organisation to use Darktrace ActiveAI Security Portal, and also manage their roles and access to products.`)}
                    </span>
                  </div>

                  <Button style={{ marginTop: "1rem" }} variant="secondary" onClick={() => navigate("/user-management")}>
                    {t(`User Management`)}
                  </Button>
                </div>
              </Section>
            )}
          </div>
        </div>
      </FlatCard>
    </>
  );
}
