import React, { useState } from "react";
import { useActiveClientId, useMutliClientUserAccessFlags, useQueryParams } from "../../logic/hooks.js";
import { useClientApplicationsQuery, useMultiInstanceProductScopesQuery } from "../../logic/api.js";
import { Button, Card, CollapsableCard, Dropdown, IconButton, Section, Tile, TileContainer } from "@darktrace/ui-components";
import { ClientApplicationDetailsModal } from "./ClientApplicationDetailsModal.jsx";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ManageCustomDomainsModal } from "../ManageCustomDomainsModal.jsx";

const StyledClientApplicationsSidepanel = styled(CollapsableCard)`
  flex-shrink: 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;

    .inner-card {
      width: 36.8rem;

      .tenant-card-contents {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.8rem;

        .dt-ui-dropdown-button:disabled {
          opacity: 100%;
          cursor: default;

          &:after {
            content: none;
          }
        }
      }
    }

    .applications-card-contents {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .create-application-btn {
        width: 100%;
      }

      .applications {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .dt-ui-tile__body {
          padding-top: 0;
        }
      }
    }
  }
`;

export function ClientApplicationsSidepanel({ selectedApplicationId, setSelectedApplicationId }) {
  const dispatch = useDispatch();
  const activeClientId = useActiveClientId();
  const { updateQueryParams } = useQueryParams();

  const [collapsed, setCollapsed] = useState(false);
  const [isManageCustomDomainsModalOpen, setIsManageCustomDomainsModalOpen] = useState(false);
  const [showClientApplicationDetailsModal, setShowClientApplicationDetailsModal] = useState(false);

  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const clients = useSelector((state) => state.app.clients);

  const { data: clientApplications = [] } = useClientApplicationsQuery({ clientId: activeClientId });
  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();

  const { data: mulitInstanceProductScopes = [] } = useMultiInstanceProductScopesQuery({
    clientId: activeClientId,
    enabled: !!activeClientId,
  });

  const filteredClients = clientApplications.map((client) => {
    const filteredScopes = client?.scopes?.filter((scope) => mulitInstanceProductScopes.some((obj2) => scope.id === obj2.instanceId));
    return { ...client, scopes: filteredScopes };
  });

  const availableUserManagementClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("client-management:single-sign-on"))
    .map(({ clientId }) => clientId);

  const defaultClientLabel = clients.find((client) => client.id === defaultClientId)?.label;
  const clientDropdownItems = clients.map((client) => ({
    id: client.id,
    label: client.label,
    selected: client.id === activeClientId,
    disabled: !availableUserManagementClientIds.includes(client.id),
  }));

  return (
    <StyledClientApplicationsSidepanel
      className="integrations-sidebar"
      title={t(`Darktrace ActiveAI Security Portal`)}
      width={400}
      collapsed={collapsed}
      onClickCollapsedToggle={() => setCollapsed((prev) => !prev)}
    >
      {!collapsed && (
        <div className="content">
          {collapsed ? (
            <Tile className="collapsed-filter-tile" icon="buildings" onClick={() => dispatch(setCollapsed(false))} noColorBar />
          ) : (
            <Card
              className="inner-card"
              title={t(`Tenant`)}
              icon={"buildings"}
              titleRight={
                <>
                  <IconButton icon="cog" variant="tertiary" onClick={() => setIsManageCustomDomainsModalOpen((prev) => !prev)} />
                  <ManageCustomDomainsModal
                    open={isManageCustomDomainsModalOpen}
                    onClose={() => setIsManageCustomDomainsModalOpen(false)}
                  />
                </>
              }
            >
              <div className="tenant-card-contents">
                <Dropdown displayValue={defaultClientLabel} disabled />
                {clientDropdownItems.some((item) => item.id !== defaultClientId) && (
                  <Dropdown
                    label="View Users for:"
                    items={clientDropdownItems}
                    onSelect={(id) => updateQueryParams({ clientId: id })}
                    hasSearch
                  />
                )}
              </div>
            </Card>
          )}

          <Card title={t(`Applications`)}>
            <div className="applications-card-contents">
              <Button
                className="create-application-btn"
                icon="plus"
                size="small"
                onClick={() => setShowClientApplicationDetailsModal((prev) => !prev)}
              >
                {t(`Create New Application`)}
              </Button>

              <div className="applications">
                {filteredClients.map((application) => (
                  <Tile
                    key={application.id}
                    active={selectedApplicationId === application.id}
                    noColorBar
                    header={`${application.name} - ${application.scopes.length} ${application.scopes.length === 1 ? t(`scope`) : t(`scopes`)}`}
                    body={`${application.clientId}`}
                    onClick={() => setSelectedApplicationId(application.id)}
                  />
                ))}
              </div>
            </div>
          </Card>
        </div>
      )}

      <ClientApplicationDetailsModal
        open={showClientApplicationDetailsModal}
        onClose={() => setShowClientApplicationDetailsModal(false)}
        selectedApplicationId={null}
        setSelectedApplicationId={setSelectedApplicationId}
      />
    </StyledClientApplicationsSidepanel>
  );
}
