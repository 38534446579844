import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { TimeoutOverlay } from "./TimeoutOverlay.jsx";
import { Home } from "./Home.jsx";
import { loginToProduct, useMyMultiClientProductAccessInstancesStream } from "../logic/api.js";
import { ModalCard } from "@darktrace/ui-components";
import { ClientSelectorSubheader } from "./user-management/ClientSelectorSubheader.jsx";
import { PRODUCT_INFO_MAP } from "../logic/util.js";
import { ProductLoginModalTile } from "./dashboard/ProductLoginModal.jsx";

const StyledApp = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
  background-color: var(--dt-ui-page-bg);
`;

export function App({ pages }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") navigate("/dashboard");
  }, [location.pathname]);

  const showTimeoutOverlay = useSelector((state) => state.app.showTimeoutOverlay);
  const [showProductRedirectModal, setShowProductRedirectModal] = useState(false);

  // product autoredirect
  const localStorageProduct = useMemo(() => localStorage.getItem("product"), []);
  const localStorageProductPath = useMemo(() => localStorage.getItem("product_path"), []);

  const clients = useSelector((state) => state.app.clients);

  const {
    data: multiClientProductAccessInstances = [],
    isFetching: isLoadingMultiClientProductAccessInstances,
    isFetched,
    isError,
  } = useMyMultiClientProductAccessInstancesStream({ clientIds: clients.map((client) => client.id), enabled: !!localStorageProduct });

  const instancesUserCanRedirectTo = multiClientProductAccessInstances.filter((instance) => {
    if (instance.instanceId === localStorageProduct) return true;
    if (instance.serviceType === localStorageProduct) return true;
    if (instance.serviceType?.split("-service-type")?.[0]?.toLowerCase() === localStorageProduct.toLowerCase()) return true;
    if (instance.serviceType?.split("sabre-")?.[1]?.toLowerCase() === localStorageProduct.toLowerCase()) return true;
    if (instance.instanceName?.toLowerCase() === localStorageProduct.toLowerCase()) return true;
    if (
      instance.instanceName.endsWith("Threat Visualiser") &&
      ["threat visualiser", "threat visualizer"].includes(localStorageProduct.toLowerCase())
    )
      return true;

    return false;
  });

  const isAutoredirecting = isFetched && !isLoadingMultiClientProductAccessInstances && instancesUserCanRedirectTo.length === 1 && !isError;
  const instancesHash = instancesUserCanRedirectTo.map((instance) => `${instance.instanceId}:${instance.clientId}`).join(",");

  useEffect(() => {
    const _isAutoredirecting =
      isFetched && !isLoadingMultiClientProductAccessInstances && instancesUserCanRedirectTo.length === 1 && !isError;
    if (_isAutoredirecting) {
      const instance = instancesUserCanRedirectTo[0];
      loginToProduct({
        clientId: instance.clientId,
        instanceId: instance.instanceId,
        serviceId: instance.serviceId,
        productPath: localStorageProductPath,
        newTab: false,
      });
    }

    if (instancesUserCanRedirectTo.length > 1) setShowProductRedirectModal(true);
  }, [(instancesHash, isFetched, isLoadingMultiClientProductAccessInstances, instancesUserCanRedirectTo.length)]);

  if (isLoadingMultiClientProductAccessInstances) return null;
  if (isAutoredirecting) return null;
  else {
    return (
      <StyledApp>
        <Home pages={pages}>
          <Outlet />
          <TimeoutOverlay open={showTimeoutOverlay} />
          <ProductRedirectModal
            open={showProductRedirectModal}
            onClose={() => setShowProductRedirectModal(false)}
            instances={instancesUserCanRedirectTo}
            productPath={localStorageProductPath}
            product={localStorageProduct}
          />
        </Home>
      </StyledApp>
    );
  }
}

const StyledProductRedirectModal = styled(ModalCard)`
  .product-tiles {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
`;

export function ProductRedirectModal({ open, onClose, instances = [], product, productPath }) {
  const modalRoot = document.querySelector("#modal-root");
  const productName = PRODUCT_INFO_MAP[product]?.name ?? product;

  const [selectedClientId, setSelectedClientId] = useState("all-clients");
  const clients = useSelector((state) => state.app.clients);

  const instancesGroupedByClient = instances.reduce((prevInstancesGroupedByClient, instance) => {
    const instanceClientId = instance.clientId;
    if (!prevInstancesGroupedByClient[instanceClientId]) prevInstancesGroupedByClient[instanceClientId] = [];
    prevInstancesGroupedByClient[instanceClientId].push(instance);
    return prevInstancesGroupedByClient;
  }, {});
  const instancesForSelectedClient = selectedClientId === "all-clients" ? instances : instancesGroupedByClient[selectedClientId];

  const availableClients = [
    ...new Set(
      instances.map((item) => {
        const client = clients.find((client) => client.id === item.clientId);
        return client;
      }),
    ),
  ];

  let dropdownOptions = [
    { id: "all-clients", label: t(`All`), selected: selectedClientId === "all-clients" },
    ...availableClients.map((client) => ({ ...client, selected: client.id === selectedClientId })),
  ];

  return (
    <StyledProductRedirectModal
      title={t(`Go to Product`)}
      open={open}
      modalRoot={modalRoot}
      onClose={onClose}
      subheaders={[
        ...(availableClients.length > 1
          ? [
              {
                size: "large",
                component: (
                  <>
                    <label>{t(`Tenant:`)}</label>
                    <ClientSelectorSubheader clients={dropdownOptions} onSelectClient={(clientId) => setSelectedClientId(clientId)} />
                  </>
                ),
              },
            ]
          : []),
      ]}
    >
      {selectedClientId !== "all-clients" && (
        <div className="product-tiles">
          {instancesForSelectedClient.map((instance, index) => (
            <ProductLoginModalTile
              key={instance.instanceId}
              instance={instance}
              instanceCount={instancesForSelectedClient.length}
              instanceIndex={index}
              productName={productName}
              productPath={productPath}
            />
          ))}
        </div>
      )}

      {selectedClientId === "all-clients" && (
        <>
          {availableClients.map((client, clientIndex) => {
            const instancesForClient = instancesGroupedByClient[client.id];
            const isLastClient = clientIndex === availableClients.length - 1;
            if (client.id === "all-clients") return;

            return (
              <>
                <label key={`${client.id}_label`}>{client.label}</label>
                <br />
                <br />
                <div className="product-tiles">
                  {instancesForClient.map((instance, instanceIndex) => (
                    <ProductLoginModalTile
                      key={instance.instanceId}
                      instance={instance}
                      instanceIndex={instanceIndex}
                      instanceCount={instancesForClient.length}
                      productName={productName}
                      productPath={productPath}
                    />
                  ))}
                </div>
                {!isLastClient && <div style={{ borderBottom: `2px solid var(--dt-ui-polar-dark-ice-200)`, marginTop: `1rem` }}></div>}
                <br />
              </>
            );
          })}
        </>
      )}
    </StyledProductRedirectModal>
  );
}
