import { Accordion, Card, IconButton, Tooltip } from "@darktrace/ui-components";
import React from "react";

export function ProductConnectionStatusCard({ showProductConnectionStatus, setShowProductConnectionStatus, accessInstances }) {
  return (
    <Accordion collapsed={!showProductConnectionStatus} horizontal>
      <Card
        className="instances-card"
        icon="triangle-exclamation fa-xl "
        title={t(`Product Connection Status`)}
        container
        titleRight={<IconButton icon="xmark" variant="tertiary" onClick={() => setShowProductConnectionStatus(false)} />}
      >
        <div className="instances">
          <div className="instance-statuses">
            {accessInstances
              .filter((instance) => instance.state)
              .map((instance) => {
                const instanceStatusTooltipText =
                  instance.state === "NONE"
                    ? t(`This product has not yet been connected.`)
                    : instance.state === "DOWN"
                      ? t(`This product is currently unavailable.`)
                      : instance.state === "UP"
                        ? t(`This product is currently available.`)
                        : null;

                return (
                  <div className="instance-status" key={instance.instanceId}>
                    <div className="instance-details">
                      <span className="instance-name">{instance.displayName}:</span>
                      <span className="instance-status-info">
                        {t(`Status`)} <span className="status-type">{instance.state}</span>
                      </span>
                    </div>

                    <Tooltip placement="left" text={instanceStatusTooltipText} />
                  </div>
                );
              })}
          </div>
        </div>
      </Card>
    </Accordion>
  );
}
