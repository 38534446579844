import React from "react";
import { Dropdown, ModalCard, Table } from "@darktrace/ui-components";
import styled from "styled-components";
import { useActiveClientId } from "../../logic/hooks.js";
import {
  useAddClientApplicationScopeMutation,
  useMultiInstanceProductScopesQuery,
  useRemoveClientApplicationScopeMutation,
} from "../../logic/api.js";
import { useMutationState } from "@tanstack/react-query";
import { NoData } from "../shared/NoData.jsx";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 90vh;
    width: min(70%, 80rem);

    .dt-ui-card__contents {
      padding: 0;

      .edit-scopes-modal-content {
        .dt-ui-table {
          width: 100%;

          .instance-name {
            white-space: nowrap;
          }

          .product-column {
            width: 0;
          }
        }
      }
    }
  }
`;

function removeQuotes(scopeLabel) {
  if (scopeLabel.startsWith("'") && scopeLabel.endsWith("'")) return scopeLabel.slice(1, -1);
  return scopeLabel;
}

export function EditClientApplicationScopeModal({ open, onClose = () => {}, application }) {
  const activeClientId = useActiveClientId();
  const modalRoot = document.querySelector("#modal-root");

  const { data: multiInstanceProductScopes = [] } = useMultiInstanceProductScopesQuery({
    clientId: activeClientId,
    enabled: !!activeClientId,
  });

  const { mutate: addClientApplicationScope } = useAddClientApplicationScopeMutation({ ccaId: application?.id, clientId: activeClientId });
  const { mutate: removeClientApplicationScope } = useRemoveClientApplicationScopeMutation({
    ccaId: application?.id,
    clientId: activeClientId,
  });

  const scopesBeingAdded = useMutationState({
    filters: { mutationKey: ["add-client-application-scope"], status: "pending" },
    select: (mutation) => mutation.state.variables,
  });

  const scopeIdsBeingRemoved = useMutationState({
    filters: { mutationKey: ["remove-client-application-scope"], status: "pending" },
    select: (mutation) => mutation.state.variables.scopeId,
  });

  const tableHeaders = [
    { id: "product", title: t(`Product`), className: "product-column" },
    { id: "scopes", title: t(`Scopes`) },
  ];

  const tableRows = multiInstanceProductScopes.map(({ groups, instanceName, instanceId }) => {
    const dropdownItems = groups.map((group) => {
      const scope = application.scopes.find((scope) => scope.instanceId === instanceId && scope.scope === group);
      const isBeingRemoved = scopeIdsBeingRemoved.includes(scope?.id);
      const isBeingAdded = scopesBeingAdded.some((scope) => scope.productGroup === group && scope.instanceId === instanceId);

      return {
        id: group,
        label: removeQuotes(group),
        selected: !!scope,
        disabled: isBeingAdded || isBeingRemoved,
      };
    });

    function toggleClientApplicationScope({ newValue, groupId }) {
      const scopeId = application.scopes.find((scope) => scope.instanceId === instanceId && scope.scope === groupId)?.id;
      if (newValue) addClientApplicationScope({ productGroup: groupId, instanceId });
      else removeClientApplicationScope({ scopeId });
    }

    return {
      rowId: instanceId,
      data: [
        <span key="instance-name" className="instance-name">
          {instanceName}
        </span>,
        <Dropdown
          key={"scopes-dropdown"}
          checkbox
          items={dropdownItems}
          onSelect={(groupId) => {
            const newValue = !dropdownItems.find((item) => item.id === groupId)?.selected;
            toggleClientApplicationScope({ newValue, groupId });
          }}
        />,
      ],
    };
  });

  return (
    <StyledModalCard
      keepMounted={false}
      open={open}
      modalRoot={modalRoot}
      title={t(`Edit Application Scopes: {0}`, application?.name)}
      onClose={onClose}
    >
      <div className="edit-scopes-modal-content">
        {tableRows.length === 0 ? (
          <NoData text={t(`None of your available products are accessible by Client Applications`)}></NoData>
        ) : (
          <Table rows={tableRows} headers={tableHeaders}></Table>
        )}
      </div>
    </StyledModalCard>
  );
}
