import { Button, ModalCard, Section } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { AnalystTile } from "../../shared/AnalystTile.jsx";
import { useActiveClientId } from "../../../../logic/hooks.js";
import { assignUser, useAnalystShifts, useUserProfileInfo } from "../../../../logic/api.js";
import { ShiftButtonGroup } from "../../shared/ShiftButtonGroup.jsx";
import { AnalystList } from "../../shared/AnalystList.jsx";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-card__contents {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dt-ui-section {
        flex-grow: 1;
        margin-right: 1.2rem;
      }
    }
    .buttons {
      display: flex;
      gap: 0.8rem;
      button {
        flex-grow: 1;
      }
    }
  }
`;

export function AssignModal({ alertId, open, onClose = () => {}, assigneeId = null }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const { data: userProfileInfo } = useUserProfileInfo();
  const yourUserId = userProfileInfo?.user?.id;

  const [selectedUser, setSelectedUser] = useState(assigneeId);
  const [shiftView, setShiftView] = useState("current");
  const { data: analystShifts } = useAnalystShifts({ clientId: activeClientId, userId: yourUserId });

  function handleAssign() {
    assignUser({ alertId: alertId, clientId: activeClientId, userId: selectedUser });
    onClose();
  }

  return (
    <StyledModalCard modalRoot={modalRoot} open={open} onClose={onClose} title={t(`Assign #{0}`, alertId)} icon="user-shield">
      <AnalystTile name={userProfileInfo.user.name} active={yourUserId === selectedUser} onClick={() => setSelectedUser(yourUserId)} />
      <span className="controls">
        <Section title={t(`SOC Analysts`)} />
        <ShiftButtonGroup active={shiftView} handover={analystShifts?.handover} setActive={setShiftView} />
      </span>
      <AnalystList activeId={selectedUser} onClick={(id) => setSelectedUser(id)} {...{ shiftView, analystShifts }} />
      <span className="buttons">
        <Button onClick={onClose} variant="secondary">
          {t(`Cancel`)}
        </Button>
        <Button disabled={!selectedUser || selectedUser === assigneeId} onClick={handleAssign}>
          {t(`Assign`)}
        </Button>
      </span>
    </StyledModalCard>
  );
}
