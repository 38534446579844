import React from "react";
import { Sidebar } from "@darktrace/ui-components";
// import darktraceLogoIcon from "../../public/dt-assets/icon/1x/dt-icon-orange-gradient-square.png";
import darktraceLogoIcon from "../../public/dt-assets/icon/4x/dt-icon-activeai-gradient.png";
import darktraceLogoText from "../../public/images/ActiveAISec-Text.png";
import { useQueryParams } from "../logic/hooks.js";
import { useNavigate, createSearchParams } from "react-router-dom";
// import packageJson from "../../package.json";
import styled from "styled-components";

const StyledSidebar = styled(Sidebar)`
  &.dashboard-only {
    --sidebar-max-width: var(--sidebar-base-width);

    height: var(--sidebar-base-width);
    overflow: hidden;
  }
  z-index: 99999;

  .dt-ui-sidebar__bottom {
    height: 100%;
  }
  .sidebar-footer {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 0.8rem;
  }
`;

export function WelcomeSidebar({ links, selectedLinkId }) {
  const navigate = useNavigate();
  const { queryParams, updateQueryParams } = useQueryParams();

  const dashboardOnly = links.length === 1 && links[0].id === "/dashboard";
  const dashboardOnlyClass = dashboardOnly ? "dashboard-only" : "";

  function handleClickLink(id) {
    const queryParamsToKeep = { ...queryParams };
    delete queryParamsToKeep.search;
    delete queryParamsToKeep.roles;
    delete queryParamsToKeep.registrationStatus;
    delete queryParamsToKeep.orderDir;

    if (id !== selectedLinkId) {
      updateQueryParams({ search: "", roles: "" });
      navigate({ pathname: id, search: createSearchParams(queryParamsToKeep).toString() });
    }
  }

  return (
    <StyledSidebar
      logoIcon={darktraceLogoIcon}
      logoText={darktraceLogoText}
      links={links}
      onClickLink={handleClickLink}
      selectedLinkId={selectedLinkId}
      className={dashboardOnlyClass}
    />
  );
}
