import React from "react";
import { Card, Section } from "@darktrace/ui-components";
import styled from "styled-components";

const StyledSAMLSetupGuideCard = styled(Card)`
  .contents {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

export function SAMLSetupGuideCard() {
  return (
    <StyledSAMLSetupGuideCard container icon="screwdriver-wrench" className="saml-setup-guide-card" title="SAML Setup Guide">
      <div className="contents">
        <Section title={"What is SAML"} />
        {/* prettier-ignore */}
        <span>
          {t(`Security Assertion Markup Language, or SAML, is a standardised way to tell external applications and services that a user is who they say they are. SAML makes single sign-on (SSO) technology possible by providing a way to authenticate a user's information in a particular format between two parties, usually an identity provider (IdP) and a web application.`,)}
        </span>

        <Section title={"Setting up SAML"} />
        {/* prettier-ignore */}
        <span>
          {t(`This page allows you to set up a SAML provider. The assertion endpoint you should use is ${window.location.origin}/api/saml/assert. Enter the contents of the XML field into the SAML Configuration XML field. Enter the SAML Email, Name and Group attribute names, configured on the SAML provider. Once the SAML provider is set up you can create role mapping for that particular SAML Group. From there you can create SAML group mapping for each of the Darktrace products. SAML group mapping allows you to manage users permissions and roles within each of the products.`,)}
        </span>

        {/* <div className="links">
          <span>
            <a className="dt-ui-link" href="">
              Link to information 1
            </a>
            <span> and </span>
            <a className="dt-ui-link" href="">
              Link to information 2
            </a>
          </span>
        </div> */}
      </div>
    </StyledSAMLSetupGuideCard>
  );
}
