import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useClientSAMLProviders } from "../../logic/api.js";
import { useActiveClientId, useMutliClientUserAccessFlags, useQueryParams } from "../../logic/hooks.js";

import { useSelector } from "react-redux";

import { SAMLRolesCard } from "./SAMLRolesCard.jsx";
import { SAMLGroupsCard } from "./SAMLGroupsCard.jsx";

import { SAMLSetupGuideCard } from "./SAMLSetupGuideCard.jsx";
import { SSOConfigSidepanel } from "./SSOConfigSidepanel.jsx";

const StyledSSOConfig = styled.div`
  display: flex;
  gap: 2rem;
  align-items: stretch;
  height: 100%;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    flex-grow: 1;
    overflow-y: auto;
  }
`;

export function SSOConfig() {
  const activeClientId = useActiveClientId();
  const [selectedSamlProviderId, setSelectedSamlProviderId] = useState();
  const [isEditingProvider, setIsEditingProvider] = useState(false);

  const clients = useSelector((state) => state.app.clients) ?? [];
  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();

  const availableSSOConfigClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("client-management:single-sign-on"))
    .map(({ clientId }) => clientId);

  const availableSSOConfigClients = clients.map((client) => ({
    id: client.id,
    label: client.label,
    selected: client.id === activeClientId,
    disabled: !availableSSOConfigClientIds.includes(client.id),
  }));

  const canUserViewSSOConfigForActiveClient = availableSSOConfigClientIds.includes(activeClientId);

  const { data = {}, isFetched } = useClientSAMLProviders({ clientId: activeClientId, enabled: !!canUserViewSSOConfigForActiveClient });
  const { providers: clientSamlProviders = [] } = data;

  // handle auto-select SAML provider
  useEffect(() => {
    if (clientSamlProviders.length > 0) setSelectedSamlProviderId(clientSamlProviders[0].id);
    else setSelectedSamlProviderId(null);
  }, [clientSamlProviders]);

  const selectedSamlProviderInfo = clientSamlProviders?.find(({ id }) => id === selectedSamlProviderId) || {};
  const { alias } = selectedSamlProviderInfo;

  const defaultClientId = useSelector((state) => state.app.defaultClientId); // user's own client (aka default client, parent client)
  const { updateQueryParams } = useQueryParams({ defaultQueryParams: { clientId: defaultClientId } });

  return (
    <Fragment>
      <StyledSSOConfig>
        <SSOConfigSidepanel
          clients={clients}
          availableClients={availableSSOConfigClients}
          activeClientId={activeClientId}
          updateQueryParams={updateQueryParams}
          clientSamlProviders={clientSamlProviders}
          selectedSamlProviderId={selectedSamlProviderId}
          setSelectedSamlProviderId={setSelectedSamlProviderId}
          selectedSamlProviderInfo={selectedSamlProviderInfo}
          isEditingProvider={isEditingProvider}
          isFetchingProviders={!isFetched}
          setIsEditingProvider={setIsEditingProvider}
        />

        <div className="main-content">
          {canUserViewSSOConfigForActiveClient ? (
            <>
              <SAMLSetupGuideCard />

              {selectedSamlProviderId && (
                <>
                  <SAMLRolesCard clientSamlProviderId={selectedSamlProviderId} alias={alias} />
                  <SAMLGroupsCard clientSamlProviderId={selectedSamlProviderId} alias={alias} />
                </>
              )}
            </>
          ) : (
            <div>{t(`You cannot view SSO Configuration for this client. Choose another client.`)}</div>
          )}
        </div>
      </StyledSSOConfig>
    </Fragment>
  );
}
