import React from "react";
import { Tooltip } from "@darktrace/ui-components";
import { t } from "../../../logic/translator.js";
import styled from "styled-components";

const StyledCommsTable = styled.table`
  border-collapse: collapse;

  thead {
    td {
      padding: 0 0.4rem;
    }
  }

  tbody tr {
    td {
      padding: 1rem 0.4rem;
    }
    border-bottom: 0.5px solid var(--dt-ui-section-border);
  }
  .absent-method {
    opacity: 0.5;
  }
`;

const mediums = [
  { id: "email", label: t(`Email`), icon: "envelope" },
  { id: "sms", label: t(`SMS`), icon: "message-sms" },
  { id: "phone", label: t(`Phone`), icon: "phone" },
  { id: "mobile_app", label: t(`Mobile App`), icon: "mobile-screen-button" },
];

function getStateIcon(state) {
  if (!state)
    return (
      <Tooltip text={t(`Medium Not Selected`)}>
        <i className="absent-method far fa-circle"></i>
      </Tooltip>
    );
  let icon = "";
  if (state === "success") icon = "check";
  if (state === "failure") icon = "xmark";
  if (state === "pending") icon = "circle-dashed";
  return (
    <Tooltip text={(state?.[0] || "").toUpperCase() + state.substring(1).toLowerCase()}>
      <i className={`fas fa-${icon}`}></i>
    </Tooltip>
  );
}

export function CommunicationsTable({ communications }) {
  const uniqueRecipientIDs = [...new Set(communications.map((x) => x.recipient_id))];

  function getStateIndicator(medium, recipientId) {
    const state = communications.find((x) => x.recipient_id === recipientId && x.medium === medium)?.state || null;
    return getStateIcon(state);
  }

  if (!communications.length) return <div>{t(`None to show.`)}</div>;

  return (
    <StyledCommsTable>
      <thead>
        <tr>
          <td>{""}</td>
          {mediums.map((medium) => (
            <td key={medium.label}>
              <Tooltip text={medium.label}>
                <i className={`fas fa-${medium.icon}`}></i>
              </Tooltip>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {uniqueRecipientIDs.map((id) => {
          return (
            <tr key={id}>
              <td>{id}</td>
              {mediums.map((medium) => (
                <td key={medium.id}>{getStateIndicator(medium.id, id)}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </StyledCommsTable>
  );
}
