import React, { useEffect, useState, useTransition } from "react";
import styled from "styled-components";
import { Button, DropdownDropper, ModalCard } from "@darktrace/ui-components";

import { useDispatch, useSelector } from "react-redux";

import {
  addChildRoleToUser,
  addRoleToUser,
  removeChildRoleFromUser,
  removeRoleFromUser,
  useClientAvailableRoles,
  useParentClientAvailableRoles,
  useParentUserRoles,
  useUserOwnRoles,
  useUserRoles,
} from "../../logic/api.js";
import { newToast } from "../../logic/store.js";
import { queryClient } from "../../logic/index.jsx";
import { useActiveClientId, useMutliClientUserAccessFlags } from "../../logic/hooks.js";
import { UserInfoSubheader } from "./UserInfoSubheader.jsx";
import { ROLE_DESCRIPTIONS } from "../../logic/util.js";
import { ClientSelectorSubheader } from "./ClientSelectorSubheader.jsx";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;

    .dt-ui-card__contents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      flex-grow: 1;
      height: 100%;

      .dt-ui-dropdown {
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
      }

      .action-buttons {
        align-self: flex-end;
        display: flex;
        gap: 0.8rem;
        padding: 1.2rem 1.6rem;
      }

      .table-wrapper {
        overflow-x: auto;
        width: 100%;

        .dt-ui-table {
          width: 100%;
        }
      }

      td {
        .changed-field .dt-ui-dropdown-button {
          border-color: var(--dt-input-valid-border);
        }

        .dt-ui-dropdown-button,
        .dt-input__wrapper {
          width: 100%;
          min-width: 12rem;
        }
      }
    }
  }
`;

export function AssignRolesModal({ open, user, onClose = () => {}, isYou, initialClientId }) {
  const dispatch = useDispatch();
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const [selectedClientId, setSelectedClientId] = useState(initialClientId ?? activeClientId);
  const [__isPendingTransition, startTransition] = useTransition(); // eslint-disable-line

  if (user && !user.clientId) console.error("NO CLIENT ID");
  const isSelectedClientMyDefaultClient = selectedClientId === defaultClientId;
  const isSelectedClientTheUsersDefaultClient = selectedClientId === user?.clientId;

  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();
  const availableUserManagementClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("user-management"))
    .map(({ clientId }) => clientId);

  const clients = useSelector((state) => state.app.clients);
  const availableUserManagementClients = clients
    .filter((client) => {
      if (user?.clientId === defaultClientId) return availableUserManagementClientIds.includes(client.id);
      else return user?.clientId === client.id;
    })
    .map((client) => ({ ...client, selected: client.id === selectedClientId }));

  useEffect(() => {
    if (activeClientId) {
      startTransition(() => setSelectedClientId(activeClientId));
    }
  }, [activeClientId]);

  useEffect(() => {
    if (open && initialClientId) {
      startTransition(() => setSelectedClientId(initialClientId));
    }
  }, [open]);

  const canUserViewUserManagementForActiveClient = availableUserManagementClientIds.includes(activeClientId);
  const canUserViewUserManagementForDefaultClient = availableUserManagementClientIds.includes(defaultClientId);

  const { data: clientAvailableRoles = [], isLoading: isLoadingClientAvailableRoles } = useClientAvailableRoles({
    clientId: selectedClientId,
    enabled: !!open && canUserViewUserManagementForActiveClient,
  });
  const { data: parentClientAvailableRoles = [], isLoading: isLoadingParentClientAvailableRoles } = useParentClientAvailableRoles({
    clientId: selectedClientId,
    enabled:
      !!open && !isSelectedClientMyDefaultClient && canUserViewUserManagementForActiveClient && canUserViewUserManagementForDefaultClient,
  });

  const isLoadingAvailableRoles = isLoadingClientAvailableRoles || isLoadingParentClientAvailableRoles;
  const availableRoles = isSelectedClientTheUsersDefaultClient ? clientAvailableRoles : parentClientAvailableRoles;

  const userId = user?.id;
  const email = user?.email;
  const name = user?.name;

  const { data: userRoles = [] } = useUserRoles({
    clientId: selectedClientId,
    email,
    userId,
    enabled: !!open && !isYou && isSelectedClientTheUsersDefaultClient,
  });

  const { data: userOwnRoles = [] } = useUserOwnRoles({ clientId: selectedClientId, enabled: !!open && isYou });

  // TODO: only enable this if the user is an admin on this client
  const { data: parentUserRoles = [] } = useParentUserRoles({
    clientId: selectedClientId,
    email,
    userId,
    enabled: !!open && !isSelectedClientTheUsersDefaultClient,
  });

  const roles = isYou ? userOwnRoles : isSelectedClientTheUsersDefaultClient ? userRoles : parentUserRoles;

  const [localRoleIds, setLocalRoleIds] = useState([]);
  const roleIdsToAdd = localRoleIds.filter((localRoleId) => !roles.some((role) => role.id === localRoleId));
  const roleIdsToRemove = roles.filter((role) => !localRoleIds.some((localRoleId) => role.id === localRoleId)).map((role) => role.id);
  const hasChanges = roleIdsToAdd.length > 0 || roleIdsToRemove.length > 0;

  const rolesHash = roles.map((role) => role.id).join();
  useEffect(() => {
    if (roles) startTransition(() => setLocalRoleIds(roles.map((role) => role.id)));
  }, [rolesHash]);

  const localRoles = [
    ...roles
      .filter((role) => !availableRoles.some((availableRole) => availableRole.id === role.id))
      .map((role) => ({ id: role.id, selected: true, disabled: true, label: role.name })),
    ...availableRoles.map((availableRole) => ({
      ...availableRole,
      selected: localRoleIds.some((roleId) => roleId === availableRole.id),
      label: availableRole.name,
    })),
  ];

  const localRolesDropdownItems = localRoles.reduce((prevLocalRolesDropdownItems, role) => {
    const roleCategoryLabel = role.category || "Other";
    const roleCategoryId = `category::::${roleCategoryLabel}`;
    const existingCategory = prevLocalRolesDropdownItems.find((item) => item.id === roleCategoryId);

    if (existingCategory) existingCategory.items.push({ ...role, description: ROLE_DESCRIPTIONS[role.id] });
    else {
      prevLocalRolesDropdownItems.push({
        id: roleCategoryId,
        label: roleCategoryLabel,
        items: [{ ...role, description: ROLE_DESCRIPTIONS[role.id] }],
      });
    }

    return prevLocalRolesDropdownItems;
  }, []);

  const formattedLocalRolesDropdownItems = localRolesDropdownItems.map((categoryItem) => {
    const areAllCategoryItemsSelected = categoryItem.items.every((item) => item.selected);
    const areSomeCategoryItemsSelected = categoryItem.items.some((item) => item.selected);
    const areAllCategoryItemsDisabled = categoryItem.items.every((item) => item.disabled);
    return {
      ...categoryItem,
      indeterminate: areSomeCategoryItemsSelected && !areAllCategoryItemsSelected,
      selected: areAllCategoryItemsSelected,
      disabled: areAllCategoryItemsDisabled,
    };
  });

  function handleSelectRole(id) {
    if (id.startsWith("category::::")) {
      const categoryItem = formattedLocalRolesDropdownItems.find((categoryItem) => categoryItem.id === id);

      const unselectedCategoryItems = categoryItem.items.filter((item) => !item.selected);
      setLocalRoleIds((prevLocalRoleIds) => {
        if (unselectedCategoryItems.length === 0) {
          return prevLocalRoleIds.filter((roleId) => !categoryItem.items.some((item) => item.id === roleId));
        } else {
          return [...prevLocalRoleIds, ...unselectedCategoryItems.map((item) => item.id)];
        }
      });
    } else {
      setLocalRoleIds((prevLocalRoleIds) => {
        if (prevLocalRoleIds.includes(id)) return prevLocalRoleIds.filter((roleId) => roleId !== id);
        else return [...prevLocalRoleIds, id];
      });
    }
  }

  function handleSelectAllRoles() {
    setLocalRoleIds((prevLocalRoleIds) => {
      if (prevLocalRoleIds.length === availableRoles.length) return [];
      else return availableRoles.map((role) => role.id);
    });
  }

  async function handleSaveChanges() {
    try {
      const _addRoleToUser = isSelectedClientTheUsersDefaultClient ? addRoleToUser : addChildRoleToUser;
      const _removeRoleFromUser = isSelectedClientTheUsersDefaultClient ? removeRoleFromUser : removeChildRoleFromUser;

      const roleAdditionsResults = await Promise.allSettled(
        roleIdsToAdd.map((roleId) => _addRoleToUser({ clientId: selectedClientId, userId, roleId })),
      );
      const roleRemovalsResults = await Promise.allSettled(
        roleIdsToRemove.map((roleId) => _removeRoleFromUser({ clientId: selectedClientId, userId, roleId })),
      );

      const failedRoleAdditions = roleAdditionsResults
        .map((result, index) => ({ roleId: roleIdsToAdd[index], ...result }))
        .filter(({ status }) => status === "rejected");

      const failedRoleRemovals = roleRemovalsResults
        .map((result, index) => ({ roleId: roleIdsToRemove[index], ...result }))
        .filter(({ status }) => status === "rejected");

      const allFailedRequests = [...failedRoleAdditions, ...failedRoleRemovals];
      const allRequests = [...roleAdditionsResults, ...roleRemovalsResults];

      if (failedRoleAdditions.length > 0) {
        dispatch(
          newToast({
            variant: "error",
            title: t(`Unable to add {0} {1}`, failedRoleAdditions.length, failedRoleAdditions.length === 1 ? "role" : "roles"),
            delay: null,
          }),
        );
      }

      if (failedRoleRemovals.length > 0) {
        dispatch(
          newToast({
            variant: "error",
            title: t(`Unable to remove {0} {1}`, failedRoleRemovals.length, failedRoleRemovals.length === 1 ? "role" : "roles"),
            delay: null,
          }),
        );
      }

      const numSuccessfulRequests = allRequests.length - allFailedRequests.length;
      if (numSuccessfulRequests > 0) {
        dispatch(
          newToast({
            variant: "success",
            title:
              numSuccessfulRequests === 1
                ? t(`{0} user role updated successfully`, numSuccessfulRequests)
                : t(`{0} user roles updated successfully`, numSuccessfulRequests),
          }),
        );
      }

      if (allFailedRequests.length === 0) onClose();
    } finally {
      queryClient.invalidateQueries({ queryKey: ["user-profile-roles"] });
      queryClient.invalidateQueries({ queryKey: ["user-roles"] });
      queryClient.invalidateQueries({ queryKey: ["client-users"] });
      queryClient.invalidateQueries({ queryKey: ["parent-client-users"] });
      queryClient.invalidateQueries({ queryKey: ["user-access-flags"] });
    }
  }

  return (
    <StyledModalCard
      open={open}
      modalRoot={modalRoot}
      title={t(`Assign Roles: {0}`, name)}
      onClose={onClose}
      closeOnClickBackdrop={false}
      subheaders={[
        {
          size: "large",
          component: <UserInfoSubheader email={email} name={name} isYou={isYou} />,
        },
        ...(availableUserManagementClients.length > 1
          ? [
              {
                size: "large",
                component: (
                  <ClientSelectorSubheader
                    clients={availableUserManagementClients}
                    onSelectClient={(clientId) => setSelectedClientId(clientId)}
                  />
                ),
              },
            ]
          : []),
      ]}
    >
      <DropdownDropper
        checkbox
        tree
        noItemsText={isLoadingAvailableRoles ? t(`Loading...`) : null}
        items={formattedLocalRolesDropdownItems}
        usePopper={false}
        onSelect={handleSelectRole}
        onSelectAll={handleSelectAllRoles}
      />

      <div className="action-buttons">
        <Button variant="secondary" onClick={onClose}>
          {t(`Cancel`)}
        </Button>
        <Button onClick={handleSaveChanges} disabled={!hasChanges}>
          {t(`Save Changes`)}
        </Button>
      </div>
    </StyledModalCard>
  );
}
