import { Card } from "@darktrace/ui-components";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useAcademyUrlQuery } from "../logic/api.js";

const StyledLogoutPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content {
    min-width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
  }
`;

export function AcademyPage() {
  const { data: academyInfo = {}, isLoading } = useAcademyUrlQuery();
  const { url, status } = academyInfo;

  useEffect(() => {
    if (status === "Available" && url) window.open(url, "_self");
  }, [status, url]);

  return (
    <StyledLogoutPage>
      <Card title={t(`Academy`)}>
        <div className="content">
          {isLoading ? (
            <>
              <i className="fas fa-spinner fa-spin" />
              <span className="description">{t(`Loading... `)}</span>
            </>
          ) : status === "Unavailable" ? (
            <>
              <span className="description">
                {t(`Academy is not available, `)}
                <a href="/#/dashboard" target="_blank" rel="noopener noreferrer" style={{ textDecoration: `underline` }}>
                  {t(`click here`)}
                </a>
                {t(` to go to the Dashboard`)}
              </span>
            </>
          ) : (
            <span className="description">
              {t(`Redirecting to Academy, if you're not redirected within 10 seconds `)}
              <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: `underline` }}>
                {t(`click here`)}
              </a>
            </span>
          )}
        </div>
      </Card>
    </StyledLogoutPage>
  );
}
