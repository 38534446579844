import { ClickAwayListener, Dropper, IconButton, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import { EditClientApplicationScopeModal } from "./EditClientApplicationScopeModal.jsx";
import styled from "styled-components";
import { ConfirmDeleteApplicationModal } from "./ConfirmDeleteApplicationModal.jsx";

const StyledTooltip = styled(Tooltip)`
  padding: 0 !important;

  .tooltip-content {
    border-radius: 0.4rem;
    overflow: hidden;

    .created-by {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      padding: 1rem 2.4rem 0.8rem;
      border-bottom: 1px solid var(--dt-ui-field-border);
      font-size: 1.2rem;

      .creator {
        font-weight: 600;
      }
    }

    .dt-ui-dropdown {
      border-radius: 0;
      border: none;
      overflow: hidden;

      .delete-application-option,
      .delete-application-option i {
        color: var(--dt-ui-critical);
      }
    }
  }
`;

export function ClientApplicationsOptionsButton({ application, setSelectedApplicationId }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showEditScopesModal, setShowEditScopesModal] = useState(false);

  const dropdownItems = [
    { id: "edit-scopes", icon: "pencil", label: t(`Edit Application Scopes`) },
    { id: "delete-application", icon: "trash", label: t(`Delete Application`), className: "delete-application-option" },
  ];

  function handleSelectDropdownItem(id) {
    setShowTooltip(false);
    if (id === "edit-scopes") setShowEditScopesModal(true);
    if (id === "delete-application") setShowConfirmDeleteModal(true);
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowTooltip(false)} mouseEvent={"onMouseDown"}>
        <div>
          <StyledTooltip
            placement="bottom-end"
            id="client-applications-option-tooltip"
            open={showTooltip}
            text={
              <div className="tooltip-content" onClick={(e) => e.stopPropagation()}>
                <span className="created-by">
                  <span>{t(`Created by:`)}</span>
                  <span className="creator">{application?.createdBy}</span>
                </span>

                <Dropper onSelect={handleSelectDropdownItem} className="client-application-options" items={dropdownItems} />
              </div>
            }
          >
            <IconButton
              variant="secondary"
              icon="ellipsis-vertical"
              onClick={(e) => {
                e.stopPropagation();
                setShowTooltip((prev) => !prev);
              }}
            />
          </StyledTooltip>
        </div>
      </ClickAwayListener>

      <EditClientApplicationScopeModal open={showEditScopesModal} onClose={() => setShowEditScopesModal(false)} application={application} />

      <ConfirmDeleteApplicationModal
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        selectedApplication={application}
        setSelectedApplicationId={setSelectedApplicationId}
      />
    </>
  );
}
