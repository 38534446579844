import { Card, CollapsableCard, Dropdown, IconButton, Section, Tile, TileContainer } from "@darktrace/ui-components";
import React, { useState } from "react";
import { ManageCustomDomainsModal } from "../ManageCustomDomainsModal.jsx";
import { ProductTile } from "./ProductTile.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setIsSidepanelCollapsed } from "../../logic/store.js";
import { useActiveClientId, useQueryParams } from "../../logic/hooks.js";

export function DashboardSidepanel({
  canShowProductConnectionStatus,
  instancesThatAreDown,
  setShowProductConnectionStatus,
  servicesForSelectedDeployment,
  deployments,
}) {
  const activeClientId = useActiveClientId();
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.id === activeClientId }));

  const dispatch = useDispatch();
  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);
  const { updateQueryParams } = useQueryParams({ defaultQueryParams: { clientId: defaultClientId } });
  const defaultClientLabel = _clients.find((client) => client.id === defaultClientId)?.label;
  const [isManageCustomDomainsModalOpen, setIsManageCustomDomainsModalOpen] = useState(false);

  const hasMultipleDeployments = deployments.length > 1;

  return (
    <>
      <CollapsableCard
        title={t(`Darktrace ActiveAI Security Portal`)}
        width={"40rem"}
        style={{ flexShrink: "0" }}
        container={"strong"}
        controlled
        collapsed={isSidepanelCollapsed}
        onClickCollapsedToggle={() => dispatch(setIsSidepanelCollapsed(!isSidepanelCollapsed))}
      >
        <div className="content">
          {isSidepanelCollapsed ? (
            <Tile className="collapsed-filter-tile" icon="buildings" onClick={() => dispatch(setIsSidepanelCollapsed(false))} noColorBar />
          ) : (
            <Card
              className="inner-card"
              title={t(`Tenant`)}
              icon={"buildings"}
              titleRight={
                <>
                  <IconButton icon="cog" variant="tertiary" onClick={() => setIsManageCustomDomainsModalOpen((prev) => !prev)} />
                  <ManageCustomDomainsModal
                    open={isManageCustomDomainsModalOpen}
                    onClose={() => setIsManageCustomDomainsModalOpen(false)}
                  />
                </>
              }
            >
              <div className="tenant-card-contents">
                <Dropdown displayValue={defaultClientLabel} disabled />

                {clients.length > 1 && (
                  <Dropdown
                    label={`${t(`Your Products for`)}:`}
                    items={clients}
                    onSelect={(id) => updateQueryParams({ clientId: id })}
                    hasSearch
                  />
                )}

                {canShowProductConnectionStatus && (
                  <Tile
                    className="product-connection-status-tile"
                    noColorBar
                    header={t(`Product Connection Status`)}
                    headerRight={
                      <div className="product-connection-status-pill">
                        <i className="fa fa-triangle-exclamation" />
                        <span>{instancesThatAreDown.length}</span>
                      </div>
                    }
                    onClick={() => setShowProductConnectionStatus((prev) => !prev)}
                  />
                )}

                {servicesForSelectedDeployment.length > 0 && (
                  <>
                    <Section className="tenant-services-section" title={t(`Tenant Services`)} />

                    {servicesForSelectedDeployment.map((service) => {
                      return <ProductTile key={service.serviceId} {...service} type="service" />;
                    })}
                  </>
                )}
              </div>
            </Card>
          )}

          {hasMultipleDeployments &&
            (isSidepanelCollapsed ? (
              <Tile
                className="collapsed-filter-tile"
                icon="boxes-stacked"
                onClick={() => dispatch(setIsSidepanelCollapsed(false))}
                noColorBar
              />
            ) : (
              <Card className="inner-card" icon="boxes-stacked" title={t(`Deployment`)}>
                <TileContainer>
                  <Tile
                    className="deployment-tile"
                    noColorBar
                    header={t(`All Deployments`)}
                    headerRight={<IconButton icon="arrow-right" variant="tertiary" />}
                    active
                    onClick={() => {}}
                  />
                  {deployments.map((deployment) => {
                    // const quickFilterId = `role-${role.id}`;
                    return (
                      <Tile
                        key={deployment.id}
                        className="deployment-tile"
                        noColorBar
                        header={deployment.label}
                        headerRight={<IconButton icon="arrow-right" variant="tertiary" />}
                        onClick={() => {}}
                      />
                    );
                  })}
                </TileContainer>
              </Card>
            ))}
        </div>
      </CollapsableCard>
    </>
  );
}
