import React, { useState } from "react";
import { Button, Modal, Textarea } from "@darktrace/ui-components";
import styled from "styled-components";
import { escalateAlert, usePTNAlertsStockEscalateMessage, usePTNAlertsExistingEscalation } from "../../../../logic/api";
import { useActiveClientId } from "../../../../logic/hooks";

const StyledModalCard = styled(Modal)`
  .dt-ui-modal__title {
    width: fit-content;
  }
  .dt-ui-modal__contents {
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    .content-wrapper {
      padding: 1.2rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      span {
        padding-bottom: 1.2rem;
        word-break: break-word;
      }
      .dt-ui-input__wrapper {
        width: 100%;
        flex-grow: 1;
        textarea {
          min-height: 20rem;
        }
      }
    }
    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.2rem;
      background-color: var(--dt-ui-window-header-bg);
      gap: 1.2rem;
      position: absolute;
      bottom: 0.8rem;
      width: calc(100% - 1.6rem);
      button {
        flex-grow: 1;
      }
    }
  }
`;

export function EscalateModal({ alertId, open, onClose = () => {}, client, alertStatus = "" }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const { data: stockMessage } = usePTNAlertsStockEscalateMessage({
    alertId,
    clientId: activeClientId,
    enabled: alertStatus === "inprogress",
  });
  const { data: existing } = usePTNAlertsExistingEscalation({ alertId, clientId: activeClientId, enabled: alertStatus === "escalated" });
  const [comment, setComment] = useState("");

  function handleSubmit() {
    escalateAlert({ alertId: alertId, clientId: activeClientId, content: comment });
    onClose();
  }
  return (
    <StyledModalCard
      modalRoot={modalRoot}
      style={{ width: "60rem" }}
      open={open}
      onClose={onClose}
      icon="user-shield"
      title={t(`Alert Client about #{0} ({1})`, alertId, client?.codename || t(`Unknown Client`))}
    >
      <div className="content-wrapper">
        <span>{alertStatus === "escalated" ? existing?.intro_comment : stockMessage}</span>
        <span>Analyst comment:</span>
        {alertStatus === "escalated" ? (
          <div>{existing?.analyst_comment}</div>
        ) : (
          <Textarea placeholder={t(`Type here...`)} value={comment} onChange={(val) => setComment(val)} />
        )}
      </div>
      <div className="controls">
        {alertStatus === "escalated" ? (
          <Button onClick={onClose} variant="secondary">
            {t(`Close`)}
          </Button>
        ) : (
          <>
            <Button onClick={onClose} variant="secondary">
              {t(`Cancel`)}
            </Button>
            <Button disabled={!comment} onClick={handleSubmit}>
              {t(`Alert Client`)}
            </Button>
          </>
        )}
      </div>
    </StyledModalCard>
  );
}
