import React, { useRef } from "react";
import { Pill } from "@darktrace/ui-components";
import styled from "styled-components";

const StyledScopes = styled.div`
  display: flex;
  gap: 0.8rem;
  .plus-btn {
    flex-shrink: 0;
  }

  .scope-pills {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
  }
`;

function removeQuotes(scopeLabel) {
  if (scopeLabel.startsWith("'") && scopeLabel.endsWith("'")) return scopeLabel.slice(1, -1);
  return scopeLabel;
}

export function Scopes({ groups, instanceId, selectedApplication }) {
  const cellRef = useRef();
  const scopeApplications = selectedApplication.scopes;

  return (
    <StyledScopes ref={cellRef} key={"scope-application_" + instanceId} className="scope-application">
      <div className="scope-pills">
        {scopeApplications.map(({ id, scope }) => (
          <Pill key={id} disabled={!groups.find((group) => group === scope)}>
            {removeQuotes(scope)}
          </Pill>
        ))}
      </div>
    </StyledScopes>
  );
}
