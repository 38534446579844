import React from "react";
import styled from "styled-components";
import { useActiveClientId } from "../../logic/hooks.js";
import { useDeleteClientApplicationMutation } from "../../logic/api.js";
import { Button, ModalCard } from "@darktrace/ui-components";

const StyledConfirmDeleteApplicationModal = styled(ModalCard)`
  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .top {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .grid {
        display: grid;
        gap: 0.8rem 0.4rem;
        grid-template-columns: auto 1fr;

        .field-name {
          position: relative;
          top: 0.7rem;
          font-weight: 700;
        }

        .field-value {
          width: 100%;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 0.8rem;
    }
  }
`;

export function ConfirmDeleteApplicationModal({ open, onClose, selectedApplication, setSelectedApplicationId }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();

  const { mutateAsync: deleteClientApplication } = useDeleteClientApplicationMutation({
    clientId: activeClientId,
    ccaId: selectedApplication?.id,
  });

  return (
    <StyledConfirmDeleteApplicationModal open={open} modalRoot={modalRoot} onClose={onClose} title={t(`Delete Application`)}>
      <div className="content">
        <div className="top">
          <span>{t(`Are you sure you want to delete this application: ${selectedApplication?.name}`)}</span>
        </div>

        <div className="action-buttons">
          <Button variant="secondary" onClick={onClose}>
            {t(`Cancel`)}
          </Button>

          <Button
            onClick={() =>
              deleteClientApplication().then(() => {
                setSelectedApplicationId(null);
                onClose();
              })
            }
          >
            {t(`Delete`)}
          </Button>
        </div>
      </div>
    </StyledConfirmDeleteApplicationModal>
  );
}
