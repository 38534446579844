import { ModalCard, Tile, useColorTheme } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";
import { useProductLoginMutation } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { PRODUCT_INFO_MAP } from "../../logic/util.js";

const StyledProductLoginModal = styled(ModalCard)`
  .dt-ui-card {
    overflow: hidden;

    .dt-ui-card__contents {
      .product-tiles {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
      }
    }
  }
`;

export function ProductLoginModal({ open, onClose, serviceId, productName, instances = [] }) {
  return (
    <>
      <StyledProductLoginModal open={open} onClose={onClose} title={t(`Darktrace {0}`, productName)}>
        <div className="product-tiles">
          {instances.map((instance, index) => (
            <ProductLoginModalTile
              key={instance.instanceId}
              instance={instance}
              serviceId={serviceId}
              productName={productName}
              instanceIndex={index}
              instanceCount={instances.length}
            />
          ))}
        </div>
      </StyledProductLoginModal>
    </>
  );
}

const StyledProductLoginModalTile = styled(Tile)`
  .dt-ui-tile__header {
    padding-block: 0.8rem;
  }

  .tile-header-content {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    .product-icon {
      height: 1.6rem;
    }

    .instance-details {
      display: flex;
      flex-direction: column;

      .instance-title {
        font-weight: 700;
      }
    }
  }
`;
export function ProductLoginModalTile({ instance, serviceId, productName, productPath, instanceIndex, instanceCount }) {
  const activeClientId = useActiveClientId();
  const [colorTheme] = useColorTheme();

  const iconSrc =
    colorTheme == "polar-light" ? PRODUCT_INFO_MAP[instance.serviceType].logoLight : PRODUCT_INFO_MAP[instance.serviceType].logoDark;

  const { mutate: loginMutation, isPending } = useProductLoginMutation({ clientId: activeClientId });
  return (
    <StyledProductLoginModalTile
      key={instance.instanceId}
      noColorBar
      header={
        <div className="tile-header-content">
          <img className="product-icon" src={iconSrc} />
          <div className="instance-details">
            {instanceCount > 1 && <span className="instance-title">{t(`Instance {0}`, instanceIndex + 1)}</span>}
            <span className="instance-subtitle">{instance.instanceName}</span>
          </div>
        </div>
      }
      headerRight={<i className="fa fa-arrow-right" />}
      disabled={instance.disabled || isPending}
      onClick={() => loginMutation({ instanceId: instance.instanceId, serviceId, serviceLabel: productName, productPath })}
    />
  );
}
