import { Button, Card, GhostElement, IconButton, Section, Tile } from "@darktrace/ui-components";
import React from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useAlertEscalation } from "../../../logic/api";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";
import styled from "styled-components";
import dayjs from "dayjs";
import { CommunicationsTable } from "./CommunicationsTable.jsx";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledCard = styled(Card)`
  height: 100%;
  .dt-ui-card__title-left {
    > div > span {
      font-weight: 600;
    }
  }
  .dt-ui-card__contents {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
`;

const StyledTile = styled(Tile)`
  flex-grow: 1;
  justify-content: flex-start;
  span.bold {
    font-weight: 600;
  }
  .dt-ui-section {
    padding: 2rem 0 1.6rem 0;
    flex-grow: 1;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
    .column {
      display: flex;
      flex-direction: column;
      .pivot-button {
        margin-top: 1.2rem;
      }
      #recipient-list {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
      }
      #devices-breached {
        margin: 1.6rem 0 0.4rem 0;
      }
    }
  }
`;

export function EscalationCard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const activeClientId = useActiveClientId();
  const { queryParams } = useQueryParams();

  const { data: escalation, isLoading, isError } = useAlertEscalation({ alertId: id, clientId: activeClientId });
  if (isLoading) return <GhostElement />;
  return (
    <StyledCard
      title={
        <div>
          {isError ? (
            ""
          ) : (
            <>
              <span>#{escalation.id}</span> | {escalation.alert.model_name}
            </>
          )}
        </div>
      }
      titleButtons={
        <IconButton
          variant="tertiary"
          icon="times"
          onClick={() =>
            navigate({
              pathname: "overview/escalations",
              search: createSearchParams({
                status_changed_start_time: queryParams.status_changed_start_time,
                status_changed_end_time: queryParams.status_changed_end_time,
              }).toString(),
            })
          }
        />
      }
    >
      {isError ? (
        <div>{t(`No data to show.`)}</div>
      ) : (
        <>
          <Tile
            accentColor="var(--dt-ui-critical)"
            header={t(`The Darktrace SOC has triaged a high scoring alert for your organisation.`)}
            body={t(`After further manual analysis, Darktrace recommend urgent follow-up work by your own IT team.`)}
          />
          <StyledTile noColorBar>
            <div>
              <span className="bold">{t(`Alert Escalated:`)}</span> {dayjs(escalation.created_at).utc().format("DD-MM-YYYY HH:mm:ss")} (UTC)
            </div>
            <div className="row">
              <div className="column">
                <Section title={t(`Alert Details`)} />
                <span>
                  {t(`Model: {0}`, "[TO DO]")}{" "}
                  {t(`was breached at {0} (UTC)`, dayjs(escalation.alert.time).utc().format("DD-MM-YYYY HH:mm:ss"))}
                </span>
                <span id="devices-breached">{t(`Devices Breached:`)}</span>
                <span>{t(`TO DO`)}</span>
              </div>
              <div className="column">
                <Section title={t(`Actions`)} />
                <span>{t(`Please log in to Darktrace ({0}) for further details.`, escalation.alert.hostname)}</span>
                <Button className="pivot-button" variant="secondary" disabled>
                  {t(`View Alert`)}
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <Section title={t(`Analyst Comments`)} />
                <span>{escalation.terminal_comment_content || t(`None to show.`)}</span>
              </div>
              <div className="column">
                <Section title={t(`Alert Recipients`)} />
                <span id="recipient-list">
                  <CommunicationsTable communications={escalation.communications} />
                </span>
              </div>
            </div>
            <div>
              <Section title={t(`MDR Actions`)} />
              <span>{escalation.mdr_actions || t(`None to show.`)}</span>
            </div>
          </StyledTile>
        </>
      )}
    </StyledCard>
  );
}
