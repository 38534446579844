import styled from "styled-components";
import React from "react";
import { Button, Tooltip } from "@darktrace/ui-components";
import noDataImage from "../../../public/images/no-data.svg";

const StyledNoData = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: var(--ghost-element-bg);
  border-radius: 0.4rem;
  padding: 1.6rem;
  .no-results__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => (props.$size === "large" ? "1.6rem" : "0.9rem")};
    .no-results__content-text {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      font-weight: 600;
      font-size: ${(props) => (props.$size === "large" ? "2.4rem" : "1.2rem")};
      line-height: ${(props) => (props.$size === "large" ? "2.4rem" : "1.2rem")};
    }
    img {
      width: ${(props) => (props.$size === "large" ? "11.5rem" : "3.9rem")};
    }
  }
  .fa-spinner {
    font-size: ${(props) => (props.$size === "large" ? "3.6rem" : "2.8rem")} !important;
    color: var(--dt-ui-button-secondary-text-color);
  }
`;

const StyledPaddingContainer = styled.div`
  padding: ${(props) => props.$padding};
  width: 100%;
  height: 100%;
`;

/**
 * Component to display when no results are available, also optionally shows loading state.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.text="No Results"] - The main text to display.
 * @param {string} [props.secondaryText] - Optional secondary text to display.
 * @param {string} [props.tooltip] - Optional tooltip text to display next to the main text.
 * @param {boolean} [props.loading=false] - Whether to display a loading spinner instead of the "No Data" message.
 * @param {string} [props.padding] - Optional padding for the container.
 * @param {function} [props.onClick] - Optional click handler for the button.
 * @param {string} [props.buttonText] - Text to display on the button.
 * @param {string} [props.size="small"] - Size of the component, can be "small" or "large".
 * @param {Object} [props.buttonAttrs] - Additional attributes to pass to the button component.
 * @param {Object} [props.attrs] - Additional attributes to pass to the container component.
 * @returns {JSX.Element} The rendered NoData component.
 */
export function NoData({
  text = t(`No Results`),
  secondaryText,
  tooltip,
  loading = false,
  padding,
  onClick,
  buttonText,
  size = "small",
  buttonAttrs,
  ...attrs
}) {
  const NoDataContent = (
    <StyledNoData $size={size} {...attrs}>
      {loading ? (
        <i className="fas fa-spinner fa-spin" />
      ) : (
        <div className="no-results__content">
          <img src={noDataImage} />
          <span className="no-results__content-text">
            {text}
            {tooltip && (
              <Tooltip text={tooltip}>
                <i className="far fa-circle-question" />
              </Tooltip>
            )}
          </span>
          {secondaryText && <span className="no-results__content-secondary-text">{secondaryText}</span>}
          {onClick && (
            <Button onClick={onClick} {...buttonAttrs}>
              {buttonText}
            </Button>
          )}
        </div>
      )}
    </StyledNoData>
  );

  if (padding) return <StyledPaddingContainer $padding={padding}>{NoDataContent}</StyledPaddingContainer>;
  return NoDataContent;
}
