import { Button, Card } from "@darktrace/ui-components";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content {
    min-width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
  }
`;

export function ErrorPage({ errorCode = 500 }) {
  const errorMapping = {
    400: { message: t(`Bad Request`), description: t(`There was a bad request.`) },
    403: { message: t(`Forbidden`), description: t(`You are not authorised to access this content.`) },
    404: { message: t(`Page Not Found`), description: t(`This page could not be found.`) },
    500: { message: t(`Something went wrong`), description: t(`There was an issue getting this content.`) },
  };
  const { message, description } = errorMapping[errorCode];
  return (
    <StyledErrorPage>
      <Card title={message}>
        <div className="content">
          <span className="description">{description}</span>
          <Link to="/" className="back-button">
            <Button icon="arrow-left" size="small">
              {t(`Back`)}
            </Button>
          </Link>
        </div>
      </Card>
    </StyledErrorPage>
  );
}
